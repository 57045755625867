$(function(){
    var $select = $('.theta-select').find('.btn-theta');
    $select.click(function(event) {
        event.preventDefault();
        var $iframe   = $('.thetaPlayerWrap iframe');
        var iframeUrl = $iframe.attr('src').split('?');
        var params    = iframeUrl[1] || '';

        $iframe.attr('src', $(this).data('url') + '?' + params);
        $select.removeClass('active');
        $(this).addClass('active');
        return false;
    });
});
